"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setToastPlacement = setToastPlacement;
exports.TOAST_PLACEMENT = exports.TOAST_CONTAINERS = void 0;

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
var TOAST_CONTAINERS = new Map();
exports.TOAST_CONTAINERS = TOAST_CONTAINERS;
var TOAST_PLACEMENT = 'top center';
exports.TOAST_PLACEMENT = TOAST_PLACEMENT;

function setToastPlacement(placement) {
  exports.TOAST_PLACEMENT = TOAST_PLACEMENT = placement;
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = TOAST_CONTAINERS.values()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var container = _step.value;
      container.setState({
        placement: placement
      });
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator["return"] != null) {
        _iterator["return"]();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
}
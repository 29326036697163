"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Icon;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

if (process.env.SCALE_MEDIUM && process.env.SCALE_LARGE) {
  require("../../spectrum-css/icon/index.css");

  require("../../spectrum-css/icon/index-diff.css");
} else if (process.env.SCALE_LARGE) {
  require("../../spectrum-css/icon/index-lg.css");
} else {
  require("../../spectrum-css/icon/index.css");
}

var SIZES = {
  XXS: 18,
  XS: 24,
  S: 18,
  M: 24,
  L: 18,
  XL: 24,
  XXL: 24
};

function Icon(_ref) {
  var icon = _ref.icon,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'M' : _ref$size,
      className = _ref.className,
      children = _ref.children,
      ariaLabel = _ref['aria-label'],
      ariaHidden = _ref['aria-hidden'],
      _ref$role = _ref.role,
      role = _ref$role === void 0 ? 'img' : _ref$role,
      alt = _ref.alt,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["icon", "size", "className", "children", "aria-label", "aria-hidden", "role", "alt"]);
  var sizeKey = SIZES[size];
  var svg = icon && icon[sizeKey] || icon || children;

  if (typeof svg === 'string') {
    throw new Error('String icon names are deprecated. Please import icons from react-spectrum/Icon/IconName and render as <IconName />.');
  }

  if (!ariaHidden || ariaHidden === 'false') {
    ariaHidden = undefined;
  }

  return _react["default"].cloneElement(svg, _objectSpread({
    focusable: 'false',
    'aria-label': ariaLabel || alt,
    'aria-hidden': ariaLabel || alt ? ariaHidden : true,
    role: role,
    className: (0, _classnames["default"])(svg.props.className, 'spectrum-Icon', (0, _defineProperty2["default"])({}, "spectrum-Icon--size".concat(size), size), className)
  }, otherProps));
}

Icon.displayName = 'Icon';
Icon.propTypes = {
  /**
   * Size of icon, XXS to XXL
   */
  size: _propTypes["default"].string
};
import Provider from "@react/react-spectrum/Provider";
import React, { Component } from "react";
import Footer from "./structure/Footer";
import { LandingPage, OutagePage, ErrorPage, StatusPage } from "./pages/StatusPages";

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  getStatusPage() {
    const {statusCode, statusOk, statusData, nobranding} = this.props;
    switch(statusCode) {
      case 500: 
      case 501: 
      case 502: 
        return <ErrorPage nobranding="true" />;
      case 503: 
      case 504: 
        if (statusData) {
          if (statusOk) {
            return <StatusPage status={statusData} />;
          }
        }
        return <OutagePage nobranding="true" />;
    }
    return <LandingPage />;
  }

  render() {
    const {nobranding, statusCode} = this.props;
    if ( nobranding ) {
      return (
        <Provider theme="light">
          {this.getStatusPage()}
        </Provider>
      );
    } else {
      if ( statusCode > 499 ) {
        return (
          <div className="darkError">
            {this.getStatusPage()}
          </div>
        );        

      } else {
        return (
          <Provider theme="dark">
            {this.getStatusPage()}
            <Footer />
          </Provider>
        );        
      }
    }
  }
}

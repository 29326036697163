"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Heading;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classnames = _interopRequireDefault(require("classnames"));

var _filterDOMProps = _interopRequireDefault(require("../../utils/filterDOMProps"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
var VARIANTS = {
  1: 'display',
  2: 'pageTitle',
  3: 'subtitle1',
  4: 'subtitle2',
  5: 'subtitle3',
  6: 'subtitle3'
};
var ELEMENTS = {
  'display': 'h1',
  'pageTitle': 'h2',
  'subtitle1': 'h2',
  'subtitle2': 'h3',
  'subtitle3': 'h4'
};

function Heading(_ref) {
  var variant = _ref.variant,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 1 : _ref$size,
      children = _ref.children,
      className = _ref.className,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["variant", "size", "children", "className"]);
  variant = variant || VARIANTS[size] || 'display';
  var Element = ELEMENTS[variant];
  return _react["default"].createElement(Element, (0, _extends2["default"])({
    className: (0, _classnames["default"])('spectrum-Heading', "spectrum-Heading--".concat(variant), className)
  }, (0, _filterDOMProps["default"])(otherProps)), children);
}

Heading.displayName = 'Heading';
Heading.propTypes = {
  /**
   * Variant of the heading to display. This maps to different heading HTML elements:
   * - display: h1
   * - pageTitle: h2
   * - subtitle1: h2
   * - subtitle2: h3
   * - subtitle3: h4
   */
  variant: _propTypes["default"].oneOf(['display', 'pageTitle', 'subtitle1', 'subtitle2', 'subtitle3']),

  /**
   * Custom classname to apply to the heading.
   */
  className: _propTypes["default"].string
};
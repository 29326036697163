"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Icon = require("../js/Icon");

var _Icon2 = _interopRequireDefault(_Icon);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WrappedIcon = function WrappedIcon(props) {
  return _react2.default.createElement(
    _Icon2.default,
    props,
    _react2.default.createElement(
      "svg",
      { className: "spectrum-UIIcon-CornerTriangle" },
      _react2.default.createElement("path", { d: "M4.74.01a.25.25 0 0 0-.177.073l-4.48 4.48a.25.25 0 0 0 .177.427h4.48a.25.25 0 0 0 .25-.25V.26a.25.25 0 0 0-.25-.25z", className: "spectrum-UIIcon--medium" }),
      _react2.default.createElement("path", { d: "M5.74.01a.25.25 0 0 0-.177.073l-5.48 5.48a.25.25 0 0 0 .177.427h5.48a.25.25 0 0 0 .25-.25V.26a.25.25 0 0 0-.25-.25z", className: "spectrum-UIIcon--large" })
    )
  );
};

WrappedIcon.displayName = 'CornerTriangle';
exports.default = WrappedIcon;


"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = configureTypekit;

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
function configureTypekit(typeKitId) {
  var config = {
    kitId: typeKitId,
    scriptTimeout: 3000
  };

  if (!window.Typekit) {
    // we load the typescript only once
    var h = document.getElementsByTagName('html')[0];
    h.className += ' wf-loading';
    var t = setTimeout(function () {
      h.className = h.className.replace(/(\s|^)wf-loading(\s|$)/g, ' ');
      h.className += ' wf-inactive';
    }, config.scriptTimeout);
    var tk = document.createElement('script');
    var d = false;
    tk.src = "https://use.typekit.net/".concat(config.kitId, ".js");
    tk.type = 'text/javascript';
    tk.async = 'true';

    tk.onload = tk.onreadystatechange = function onload() {
      var a = this.readyState;

      if (d || a && a !== 'complete' && a !== 'loaded') {
        return;
      }

      d = true;
      clearTimeout(t);

      try {
        window.Typekit.load(config);
      } catch (b) {
        /* empty */
      }
    };

    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(tk, s);
  }
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = convertUnsafeMethod;

var _semverCompare = _interopRequireDefault(require("semver-compare"));

var _react = _interopRequireDefault(require("react"));

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

/**
 * This is a decorator that converts UNSAFE React lifecycle methods to their pre-React 16.3.0 variant
*/
function convertUnsafeMethod(WrappedComponent) {
  var proto = WrappedComponent.prototype;
  var UNSAFE_componentWillUpdate = proto.UNSAFE_componentWillUpdate;
  var UNSAFE_componentWillMount = proto.UNSAFE_componentWillMount;
  var UNSAFE_componentWillReceiveProps = proto.UNSAFE_componentWillReceiveProps;

  if ((0, _semverCompare["default"])(_react["default"].version, '16.3.0') === -1) {
    if (UNSAFE_componentWillUpdate) {
      proto.componentWillUpdate = function (props, state) {
        UNSAFE_componentWillUpdate.apply(this, arguments);
      };
    }

    if (UNSAFE_componentWillMount) {
      proto.componentWillMount = function () {
        UNSAFE_componentWillMount.apply(this, arguments);
      };
    }

    if (UNSAFE_componentWillReceiveProps) {
      proto.componentWillReceiveProps = function (props) {
        UNSAFE_componentWillReceiveProps.apply(this, arguments);
      };
    }
  }
}
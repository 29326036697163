import React, { PureComponent } from "react";
import Banner from "@react/react-spectrum/Banner";
import Heading from "@react/react-spectrum/Heading";
import Button from "@react/react-spectrum/Button";
import StatusLight from "@react/react-spectrum/StatusLight";
import AdobeLogo from "../elements/landing/AdobeLogo";
import Background from "../elements/landing/Background";
import "./StatusPage.scss";




class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
  }
  render()  {
    return (
      <div className="wrap">
        <Background />
        <AdobeLogo />
        <div id="login-box">
          <div id="leftbox" className="box">
            <div className="header">
              <Heading>AEM Cloud Service</Heading>
            </div>
            <Heading variant="pageTitle">Adobe Experience Manager</Heading>
            <div>
            this page could soon be yours… find out more at <a href="https://www.adobe.com/marketing/experience-manager/cloud-service.html">https://www.adobe.com/marketing/experience-manager/cloud-service.html</a>
            </div>
          </div>
        </div>
      </div>
    );
  
  };
}

class OutagePage extends PureComponent {
  constructor(props) {
    super(props);
  }
  render()  {
    const {nobranding} = this.props;
    if ( nobranding ) {
      return (
        <div className="no-branding" >
            Service Outage: Looks like we are having some issues with our service. 
            We are working hard to bring it online again. 
        </div>
      );

    } else {
      return (
        <div className="wrap">
          <Background />
          <AdobeLogo />
          <div id="login-box">
            <div id="leftbox" className="box">
              <div className="header">
                <Heading>AEM Cloud Service</Heading>
              </div>
              <Heading variant="pageTitle">Service Outage</Heading>
              <div>
               Looks like we are having some issues with our service. 
               We are working hard to bring it online again. 
               For more details, please check <a href="https://status.adobe.com">status.adobe.com</a>
              </div>
            </div>
          </div>
        </div>
      );
  
    }
  };
}

class ErrorPage extends PureComponent {
  constructor(props) {
    super(props);
  }

  render()  {
    const {nobranding} = this.props;
    if ( nobranding ) {
      return (
        <div className="no-branding">
            Unexpected Error: Looks like we are having some issues with our service. 
            We are working hard to bring it online again. 
        </div>
      );

    } else {
      return (
        <div className="wrap">
          <Background />
          <AdobeLogo />
          <div id="login-box">
            <div id="leftbox" className="box">
              <div className="header">
                <Heading>AEM Cloud Service</Heading>
              </div>
              <Heading variant="pageTitle">Unexpected Error</Heading>
              <div>
              Looks like we are having some issues with our service. 
              We are working hard to bring it online again. 
              For more details, please check <a href="https://status.adobe.com">status.adobe.com</a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

class StatusPage extends PureComponent {
  constructor(props) {
    super(props);
    this.toDeveloperConsole = this.toDeveloperConsole.bind(this);
  }

  toDeveloperConsole(e) {
    const {status} = this.props;
    window.location = status.devConsoleUrl;
    e.preventDefault();
  }



  render()  {
    const {status} = this.props;
    var heading, notice, variant, message;
    if (status.state === "hibernated" ) {
      heading = "Environment Hibernated";
      notice = "hibernated";
      message = "Environment has been hibernated so cannot be accessed. You can de-hibernate it from the Developer Console.";
      variant = "notice";
    } else if ( status.state == "starting" ) {
      heading = "Environment is being de-hibernated";
      notice = "de-hibernating";
      message = "Environment is being de-hibernated and cannot be accessed. This process takes around 5 minutes to complete."
      variant = "info";
    } else  {
      return (  <OutagePage/> );
    }


    return (
      <div className="wrap">
        <Background />
        <AdobeLogo />
        <div id="login-box">
          <div id="leftbox" className="box">
            <div className="header">
              <Heading>AEM Cloud Service</Heading>
            </div>
            <Heading variant="pageTitle">{heading}</Heading>
            <div className="status-page">
              <div className="status">
              {status.environment}
              <StatusLight variant={variant} >{notice}</StatusLight>
              </div>
              <div>
               {message}
              </div>
              <div>
               <Button label="Developer Console" variant="cta" onClick={this.toDeveloperConsole} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

module.exports = {
  StatusPage,
  LandingPage,
  OutagePage,
  ErrorPage
};


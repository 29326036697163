"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = StatusLight;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classnames = _interopRequireDefault(require("classnames"));

var _filterDOMProps = _interopRequireDefault(require("../../utils/filterDOMProps"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
if (process.env.SCALE_MEDIUM && process.env.SCALE_LARGE) {
  require("../../spectrum-css/statuslight/index.css");

  require("../../spectrum-css/statuslight/index-diff.css");
} else if (process.env.SCALE_LARGE) {
  require("../../spectrum-css/statuslight/index-lg.css");
} else {
  require("../../spectrum-css/statuslight/index.css");
}

if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_LIGHT) require("../../spectrum-css/statuslight/multiStops/light.css");
if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_DARK) require("../../spectrum-css/statuslight/multiStops/dark.css");
if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_LIGHTEST) require("../../spectrum-css/statuslight/multiStops/lightest.css");
if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_DARKEST) require("../../spectrum-css/statuslight/multiStops/darkest.css");
var VARIANTS = ['celery', 'yellow', 'fuchsia', 'indigo', 'seafoam', 'chartreuse', 'magenta', 'purple', 'neutral', 'positive', 'notice', 'negative', 'info']; // For backward compatibility

var DEPRECATED_VARIANTS = {
  archived: 'neutral',
  active: 'info'
};

function StatusLight(_ref) {
  var _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? VARIANTS[0] : _ref$variant,
      children = _ref.children,
      disabled = _ref.disabled,
      className = _ref.className,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["variant", "children", "disabled", "className"]);

  if (DEPRECATED_VARIANTS[variant]) {
    console.warn("The \"".concat(variant, "\" variant of StatusLight is deprecated. Please use \"").concat(DEPRECATED_VARIANTS[variant], "\" instead."));
    variant = DEPRECATED_VARIANTS[variant];
  }

  return _react["default"].createElement("div", (0, _extends2["default"])({
    className: (0, _classnames["default"])('spectrum-StatusLight', "spectrum-StatusLight--".concat(variant), {
      'is-disabled': disabled
    }, className)
  }, (0, _filterDOMProps["default"])(otherProps)), children);
}

StatusLight.displayName = 'StatusLight';
StatusLight.propTypes = {
  /**
   * The css class for the status light, it's applied to the top level div.
   */
  className: _propTypes["default"].string,

  /**
   * The variant changes the color of the status light.
   */
  variant: _propTypes["default"].oneOf(VARIANTS),

  /**
   * Greys out the light and label
   */
  disabled: _propTypes["default"].bool
};
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Banner;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classnames = _interopRequireDefault(require("classnames"));

var _filterDOMProps = _interopRequireDefault(require("../../utils/filterDOMProps"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
if (process.env.SCALE_MEDIUM && process.env.SCALE_LARGE) {
  require("../../spectrum-css/banner/index.css");

  require("../../spectrum-css/banner/index-diff.css");
} else if (process.env.SCALE_LARGE) {
  require("../../spectrum-css/banner/index-lg.css");
} else {
  require("../../spectrum-css/banner/index.css");
}

if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_LIGHT) require("../../spectrum-css/banner/multiStops/light.css");
if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_DARK) require("../../spectrum-css/banner/multiStops/dark.css");
if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_LIGHTEST) require("../../spectrum-css/banner/multiStops/lightest.css");
if (!process.env.THEME_LIGHT && !process.env.THEME_DARK && !process.env.THEME_LIGHTEST && !process.env.THEME_DARKEST || process.env.THEME_DARKEST) require("../../spectrum-css/banner/multiStops/darkest.css");
var variants = ['info', 'warning', 'error'];
/**
 * A banner is a label with a few specific states that optionally allows for corner placement.
 */

function Banner(_ref) {
  var header = _ref.header,
      content = _ref.content,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? variants[0] : _ref$variant,
      corner = _ref.corner,
      className = _ref.className,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["header", "content", "variant", "corner", "className"]);
  return _react["default"].createElement("div", (0, _extends2["default"])({
    className: (0, _classnames["default"])('spectrum-Banner', "spectrum-Banner--".concat(variant), (0, _defineProperty2["default"])({}, 'spectrum-Banner--corner', !!corner), className)
  }, (0, _filterDOMProps["default"])(otherProps)), _react["default"].createElement("div", {
    className: "spectrum-Banner-header"
  }, header), _react["default"].createElement("div", {
    className: "spectrum-Banner-content"
  }, content));
}

Banner.displayName = 'Banner';
Banner.propTypes = {
  /** Content to show in the larger header portion of the Banner */
  header: _propTypes["default"].string,

  /** Content to show below the header portion of the Banner */
  content: _propTypes["default"].string,

  /** Variant of Banner to render */
  variant: _propTypes["default"].oneOf(variants),

  /** Whether to absolutely place the Banner in the top-right corner of the containing element */
  corner: _propTypes["default"].bool
};
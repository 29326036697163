"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cloneIcon = cloneIcon;

var _classnames = _interopRequireDefault(require("classnames"));

var _react = _interopRequireDefault(require("react"));

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
function cloneIcon(icon) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (!icon) {
    return null;
  }

  if (typeof icon === 'string') {
    throw new Error('String icon names are deprecated. Pass icons by importing them from react-spectrum/Icon/IconName and render as <IconName />.');
  }

  var className = opts.className,
      size = opts.size,
      ariaLabel = opts['aria-label'],
      ariaHidden = opts['aria-hidden'],
      _opts$alt = opts.alt,
      alt = _opts$alt === void 0 ? ariaLabel || icon.props['aria-label'] || icon.props.alt : _opts$alt;
  return _react["default"].cloneElement(icon, {
    className: (0, _classnames["default"])(className, icon.props.className),
    size: icon.props.size || size,
    'aria-label': ariaLabel || alt,
    alt: alt,
    'aria-hidden': ariaHidden || (alt ? icon.props['aria-hidden'] : true)
  });
}
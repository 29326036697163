import React from "react";

/**
 * Background image component
 */
export default function Background() {
  return (
    <div id="backgrounds">
      <div id="bg_default" className="background"></div>
    </div>
  );
}

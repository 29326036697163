import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/background.css";
import "./assets/styles/custom.scss";
import "./assets/styles/login.less";
import App from "./components/App";

const hash = window.location.hash;
const hostname = window.location.hostname;
if ( (hostname === "localhost" ) && hash.startsWith("#test_") ) {
  const test = decodeURIComponent(hash.substring("#test_".length));
  console.log("Testing",test);
  const testData = JSON.parse(test);
  const statusCode = testData.statusCode;
  const nobranding = testData.nobranding;
  const statusOk = testData.statusOk;
  const statusData = testData.statusData;
  ReactDOM.render(<App statusCode={statusCode} statusOk={statusOk} statusData={statusData} nobranding={nobranding}/>, document.getElementById("root"));
} else {
  const statusCode = +(document.getElementById("root").getAttribute("status") || 200);
  const nobranding = document.getElementById("root").getAttribute("nobranding") || false;
  const statusApi = document.getElementById("root").getAttribute("statusApi") || false;
  if ( statusApi ) {
     fetch(statusApi, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow" // manual, *follow, error
    }).then(res => {
      if (res.ok) {
        res.json().then( json => {
        ReactDOM.render(<App statusCode={statusCode} statusOk={true} statusData={json} nobranding={nobranding}/>, document.getElementById("root"));

        });
      } else {
        res.text().then(text => {
          console.log(text);
          ReactDOM.render(<App statusCode={statusCode} statusOk={false} statusData={text} nobranding={nobranding}/>, document.getElementById("root"));
        });
      }
    });
  } else {
    ReactDOM.render(<App statusCode={statusCode} nobranding={nobranding}/>, document.getElementById("root"));
  }  
}



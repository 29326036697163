"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.interpretKeyboardEvent = interpretKeyboardEvent;
exports.chain = chain;
exports.focusAfterMouseEvent = focusAfterMouseEvent;

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
function interpretKeyboardEvent(event) {
  var orientation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'vertical';

  switch (event.key) {
    case 'Enter':
    case ' ':
      if (this.onSelectFocused) {
        this.onSelectFocused(event);
      }

      break;

    case 'Tab':
      if (this.onTab) {
        this.onTab(event);
      }

      break;

    case 'PageUp':
      if (this.onPageUp) {
        this.onPageUp(event);
      } else if (this.onFocusFirst) {
        this.onFocusFirst(event);
      }

      break;

    case 'PageDown':
      if (this.onPageDown) {
        this.onPageDown(event);
      } else if (this.onFocusLast) {
        this.onFocusLast(event);
      }

      break;

    case 'Home':
      if (this.onFocusFirst) {
        this.onFocusFirst(event);
      }

      break;

    case 'End':
      if (this.onFocusLast) {
        this.onFocusLast(event);
      }

      break;

    case 'ArrowUp':
    case 'Up':
      if (event.altKey && this.onAltArrowUp) {
        this.onAltArrowUp(event);
      } else if (orientation !== 'horizontal' && this.onFocusPrevious) {
        this.onFocusPrevious(event);
      }

      break;

    case 'ArrowDown':
    case 'Down':
      if (event.altKey && this.onAltArrowDown) {
        this.onAltArrowDown(event);
      } else if (orientation !== 'horizontal' && this.onFocusNext) {
        this.onFocusNext(event);
      }

      break;

    case 'ArrowLeft':
    case 'Left':
      if (orientation !== 'vertical' && this.onFocusPrevious) {
        this.onFocusPrevious(event);
      }

      break;

    case 'ArrowRight':
    case 'Right':
      if (orientation !== 'vertical' && this.onFocusNext) {
        this.onFocusNext(event);
      }

      break;

    case 'Escape':
    case 'Esc':
      if (this.onEscape) {
        this.onEscape(event);
      }

      break;

    default: // do nothing

  }
}

function chain() {
  for (var _len = arguments.length, callbacks = new Array(_len), _key = 0; _key < _len; _key++) {
    callbacks[_key] = arguments[_key];
  }

  return function () {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = callbacks[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var callback = _step.value;

        if (typeof callback === 'function') {
          callback.apply(void 0, arguments);
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator["return"] != null) {
          _iterator["return"]();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };
}

var mouseDownPrevented = false;

function focusAfterMouseEvent(handler, event) {
  // If server side rendering, just call handler method.
  if (!document) {
    handler && handler.call(this, event);
    return;
  } // execute the handler


  if (handler) {
    handler.call(this, event);

    if (event.isDefaultPrevented()) {
      mouseDownPrevented = event.type === 'mousedown';
      return;
    }
  } // make sure that the element has focus by calling this.focus();


  if (!mouseDownPrevented && typeof this.focus === 'function') {
    this.focus();
  }

  if (event.type === 'mouseup') {
    mouseDownPrevented = false;
  }
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLocale = setLocale;
exports.getLocale = getLocale;
exports.messageFormatter = messageFormatter;
exports.defaultLocale = void 0;

var _intlMessageformat = _interopRequireDefault(require("intl-messageformat"));

/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
var defaultLocale = typeof navigator !== 'undefined' && (navigator.language || navigator.userLanguage) || 'en-US';
exports.defaultLocale = defaultLocale;
var currentLocale = defaultLocale;

function setLocale(locale) {
  currentLocale = locale;
}

function getLocale() {
  return currentLocale;
}

function messageFormatter(strings) {
  var cache = {};
  return function formatMessage(key, variables, formats) {
    var message = cache[key + '.' + currentLocale];

    if (!message) {
      var localeStrings = strings[currentLocale] || strings['en-US'];
      var msg = localeStrings[key];

      if (!msg) {
        throw new Error("Could not find intl message ".concat(key, " in ").concat(currentLocale, " locale"));
      }

      message = new _intlMessageformat["default"](msg, currentLocale, formats);
      cache[key] = message;
    }

    return message.format(variables);
  };
}
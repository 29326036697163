import React from "react";

const HELP_URL = "https://marketing.adobe.com/resources/help/en_US/home";
const PRIVACY_URL = "https://www.adobe.com/go/marketingcloud_privacy";
const TERMS_URL = "https://www.adobe.com/go/marketingcloud_terms_of_use";

const currentYear = new Date().getFullYear();

/**
 * Footer component with links and copyright information
 */
export default function Footer() {
  return (
    <div id="footer">
      <div className="legal-footer">
        <span>© {currentYear} Adobe. All Rights Reserved.</span>
        <ul id="usage-box">
          <li>
            <a href={HELP_URL}>Help</a>
          </li>
          <li>
            <a href={TERMS_URL}>Terms of Use</a>
          </li>
          <li>
            <a href={PRIVACY_URL}>Privacy Policy and Cookies</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
